/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "4rem",
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: "0.875rem",
          marginBottom: 0,
          minWidth: 80,
          borderRadius: "100%",
        }}
        imgStyle={{
          borderRadius: "50%",
        }}
      />
      <p className="ma0">
        Webaholic depuis 2013, je partage ici des tips &amp; tricks reccueillis au cours de mes pérégrinations sur le web. 
        {` `}
        <a href={`https://twitter.com/${social.twitter}`}>
          Retrouvez-moi sur twitter
        </a>
      </p>
    </div>
  )
}

export default Bio
